.errorComponent {
  border: 2px dashed rgb(187, 182, 182);
  padding: 30px;
  height: 20rem;
  margin: auto;
  border-radius: 10px;
}

.yellowErrorExclaim {
  /* border:1px solid blue; */
  display: flex;
  justify-content: center;
}

.topErrorMessage {
  color: var(--Base-Blue-grey-10, #3c3e49);

  /* Body/XXL/Medium */

  font-size: 1.375rem;
  font-style: normal;

  line-height: 2rem; /* 145.455% */
  display: flex;
  justify-content: center;
}

.errorMessage {
  color: var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3c3e49));
  text-align: center;

  /* Body/SM/Regular */

  font-size: 0.95rem;
  font-style: normal;

  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00875rem;
}

.errorBtnsContainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
