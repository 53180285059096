.description {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    margin-bottom: 1.5rem;
}

.description_box {
  border: 1px solid rgba(208, 210, 218, 1);
  border-radius: 4px;
  /* padding: 1rem; */
}

.title_button{
  display: flex;
  align-items: center;
}
