.fixed-bottom {
    position: sticky;
    bottom: 0;
    /* left: -20; */
        /* max-width: 30rem !important; */
    padding-bottom: 0.9rem;
    background-color: "rgba(248, 249, 250, 1)";
    pointer-events: none;
    /* opacity: 0.4; */
  
  }
  .activity_box {
    border: 1px solid rgba(236, 237, 240, 1);
    border-radius: 4px;
    padding: 1rem 1rem;
    height: 84vh;
    margin-top: 2rem;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  